import React, {useState} from 'react';
import './App.css';

import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Home from './Home';
import Footer from './Footer';
import Imprint from './Imprint';

import BEwegen from './projects/BEwegen';
import EnergieSpendeWald from './projects/EnergieSpendeWald';
import GemeinsamSchaukeln from './projects/GemeinsamSchaukeln';
import MoveAndSearch from './projects/MoveAndSearch';
import PopUpPool from './projects/PopUpPool';
import SalzburgTreffpunktBewegung from './projects/SalzburgTreffpunktBewegung';
import TanzMit from './projects/TanzMit';
import TreppenWunder from './projects/TreppenWunder';
import WasserSpieleLehen from './projects/WasserSpieleLehen';
import WillstDuMitMirGehen from './projects/WillstDuMitMirGehen';

import Navigation from './Navigation';
import ScrollToTop from "./ScrollToTop";
import OverlayMenu from "./OverlayMenu";

const GoHome = () => {
  const location = useLocation();
  const isHomeRoute = location.pathname === '/';

  return (
    <>
      {!isHomeRoute && <Link className="go-home" to="/">Home</Link>}
    </>
  );
};

const ProjectsNav = () => {
  const location = useLocation();
  const isHomeRoute = location.pathname === '/';
  return (
    <>
      {!isHomeRoute && <Navigation />}
    </>
  );
};

function App() {
  const { t } = useTranslation();

  const [isOverlayOpen, setOverlayOpen] = useState(false);

  const openOverlay = () => {
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
  };

  return (
    <div className="App">
      <Router>
        <header id="headerID" className="App-header">
          <LanguageSwitcher></LanguageSwitcher>
          {isOverlayOpen && <OverlayMenu closeOverlay={closeOverlay} />}
          <GoHome></GoHome>
          <link href="https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@v33.003/Vazirmatn-font-face.css" rel="stylesheet" type="text/css" />
        </header>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Imprint" element={<Imprint />} />
          <Route path="/BEwegen" element={<BEwegen />} />
          <Route path="/EnergieSpendeWald" element={<EnergieSpendeWald />} />
          <Route path="/GemeinsamSchaukeln" element={<GemeinsamSchaukeln />} />
          <Route path="/MoveAndSearch" element={<MoveAndSearch />} />
          <Route path="/PopUpPool" element={<PopUpPool />} />
          <Route path="/SalzburgTreffpunktBewegung" element={<SalzburgTreffpunktBewegung />} />
          <Route path="/TanzMit" element={<TanzMit />} />
          <Route path="/TreppenWunder" element={<TreppenWunder />} />
          <Route path="/WasserSpieleLehen" element={<WasserSpieleLehen />} />
          <Route path="/WillstDuMitMirGehen" element={<WillstDuMitMirGehen />} />
        </Routes>
        <ProjectsNav />
        <div className="fixedButtonContainer">
          <Link className="fixedButton" onClick={openOverlay}>{t('projects.h')}</Link>
        </div>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
