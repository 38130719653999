import React from 'react';
import { useTranslation } from 'react-i18next';

function Imprint() {
    const { t } = useTranslation();

    return (
        <div className='wrapper'>
            <h1>{t('imprint.h1')}</h1>
            <h2>{t('imprint.h2')}</h2>
            <h3>{t('imprint.h3')}</h3>
            <p>
                Lindhofstr. 22, 5020 Salzburg, Österreich<br></br><br></br>
                Tel. <a className="simple-link" href="tel:+435725582701">+43 (0) 5 7255 82701</a><br></br>
                Email: <a className="simple-link" href="mailto:office@dhp.lbg.ac.at">office@dhp.lbg.ac.at</a>
            </p>
        </div>
    );
};

export default Imprint;

