// BEwegen.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee2.png';

import img2 from '../images/renderings/2/02_Brunnen-Lang_weiss.png';
import img3 from '../images/renderings/2/02_BrunnenLang_CLOSEUP_weiss.png';
import img4 from '../images/renderings/2/02_BrunnenRund_CLOSEUP_weiss.png';
import img6 from '../images/renderings/2/02_BrunnenRund.png';
import img7 from '../images/renderings/2/02_laenglicher_Brunnen.png';
import img8 from '../images/renderings/2/02_runder_brunnen_neu.png';

function BEwegen() {
  const { t } = useTranslation();

  return (
    <div className='wrapper'>
      <h1>{t('BEwegen.title')}</h1>
      <LikeButton projectRef={"/BEwegen"} />
      <p className="intro">{t('BEwegen.intro')}</p>
      <p>{t('BEwegen.p1')}</p>
      <p>{t('BEwegen.p2')}</p>
      <p>{t('BEwegen.p1')}</p>
      <p>{t('BEwegen.p2')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.all')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}, {t('desc.difficultyLevel.moderate')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.yes')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.alone')}, {t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}>
          <li>Glanspitzpark</li>
          <li>Lehener Park</li>
          <li>Messezentrum</li>
          <li>Stadtwerk Lehen</li>
          <li>Franz-Josef-Kai</li>
          <li>Spielplatz Alois-Stockinger-Straße</li>
          <li>Spielplatz Salzachsee</li>
          <li>Plainberg</li>
          <li>Josef-Mayburger-Kai</li>
          <li>Spielplatz Lieferinger Tunnel</li>
          <li>Itzlinger Au</li>
          <li>Öffentliche Freizeitanlage am Glanbach Maxglan</li>
        </ol>
          <span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img2}></img>
        <img src={img3}></img>
        <img src={img4}></img>
        <img src={img7}></img>
        <img src={img8}></img>
        <img src={img6}></img>
      </div>
      <div className='videoWrapper'>
        <video id="video" controls>
          <source src="./videos/BEwegen.mp4" type="video/mp4" />
          Sorry, your browser doesn't support videos.
        </video>
      </div>
    </div>
  );
}

export default BEwegen;
