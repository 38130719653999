import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee3.png';

import img1 from '../images/renderings/3/03_Kletterspaß.jpg';
import img2 from '../images/renderings/3/03_LauterUebergang.jpg';
import img3 from '../images/renderings/3/03_Mukibude.jpg';
import img4 from '../images/renderings/3/03_Vereinsaussicht.jpg';

function MoveAndSearch() {
  const { t } = useTranslation();
  return (
    <div className='wrapper'>
      <h1>{t('MoveAndSearch.title')}</h1>
      <LikeButton projectRef={"/MoveAndSearch"} />
      <p className="intro">{t('MoveAndSearch.intro')}</p>
      <p>{t('MoveAndSearch.p1')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.all')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}, {t('desc.difficultyLevel.moderate')}, {t('desc.difficultyLevel.difficult')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.yes')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.alone')}, {t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}>
          <li>Badesee Liefering</li>
          <li>Salzachsteg - Westautobahn</li>
          <li>Alterbach</li>
          <li>City Wall Salzburg</li>
          <li>Calisthenics Park – Kaipromenade</li>
          <li>Inneres Steintor</li>
          <li>Stiftsbäckerei St. Peter</li>
          <li>Sportzentrum Mitte</li>
          <li>Surfwelle am Almkanal</li>
          <li>Stadtalm am Mönchsberg</li>
          <li>Stift Nonnberg</li>
          <li>Baron-Schwarz-Park</li>
          <li>BikePark Salzburg Süd</li>
          <li>Schloss Aigen</li>
        </ol>
          <span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img2}></img>
        <img src={img3}></img>
        <img src={img4}></img>
        <img src={img1}></img>
      </div>
      <div className='videoWrapper'>
        <video id="video" controls>
          <source src="./videos/Move.mp4" type="video/mp4" />
          Sorry, your browser doesn't support videos.
        </video>
      </div>
    </div>
  );
};

export default MoveAndSearch;

