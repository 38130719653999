import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee6.png';

import img1 from '../images/renderings/6/06_Treppenwund.png';
import img3 from '../images/renderings/6/06_Treppenwunder-CLOSEUP.png';
import img5 from '../images/renderings/6/06_Treppenwunder-CLOSEUP02.png';
import img7 from '../images/renderings/6/06_GASABE_V02__ID_06_TreppenWunder0000.png';


function TreppenWunder() {
  const { t } = useTranslation();

  return (
    <div className='wrapper'>
      <h1>{t('TreppenWunder.title')}</h1>
      <LikeButton projectRef={"/TreppenWunder"} />
      <p className="intro">{t('TreppenWunder.intro')}</p>
      <p>{t('TreppenWunder.p1')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.all')}, {t('desc.targetAudience.youth')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}, {t('desc.difficultyLevel.moderate')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.no')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.alone')}, {t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}>
          <li>Baron-Schwarz-Park</li>
          <li>Spielplatz Teisenberggasse</li>
          <li>Krauthügel</li>
          <li>Preuschenpark</li>
        </ol>
          <span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img1}></img>
        <img src={img3}></img>
        <img src={img5}></img>
        <img src={img7}></img>
      </div>
    </div>
  );
};

export default TreppenWunder;

