import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee4.png';
import img1 from '../images/renderings/4/04_Separat_Bruecke_01.png';
import img3 from '../images/renderings/4/04_Separat_Bruecke_02.png';
import img4 from '../images/renderings/4/04_Separat_Bruecke_03.png';
import img5 from '../images/renderings/4/04_Separat_Bruecke_Foto.png';


function WasserSpieleLehen() {
  const { t } = useTranslation();
  return (
    <div className='wrapper'>
      <h1>{t('WasserSpieleLehen.title')}</h1>
      <LikeButton projectRef={"/WasserSpieleLehen"} />
      <p className="intro">{t('WasserSpieleLehen.intro')}</p>
      <p>{t('WasserSpieleLehen.p1')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.all')}, {t('desc.targetAudience.adultsWithChildren')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}, {t('desc.difficultyLevel.moderate')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.no')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.alone')}, {t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}>
          <li>Über die Salzach - nördlich des Pionierstegs</li>
        </ol>
          <span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img1}></img>
        <img src={img3}></img>
        <img src={img4}></img>
        <img src={img5}></img>
      </div>
    </div>
  );
};

export default WasserSpieleLehen;

