import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee8.png';
import img1 from '../images/renderings/8/08_Salzburg-Treffpunkt_weiss.png';
import img2 from '../images/renderings/8/08_Salzburg-Treffpunkt-CLOSEUP_weiss.png';
import img5 from '../images/renderings/8/08_Treffpunkt.png';
import img8 from '../images/renderings/8/GASABE_V02__ID_08_SalzburTreffen_mitSchrift.png';


function SalzburgTreffpunktBewegung() {
  const { t } = useTranslation();

  return (
    <div className='wrapper'>
      <h1>{t('SalzburgTreffpunktBewegung.title')}</h1>
      <LikeButton projectRef={"/SalzburgTreffpunktBewegung"} />
      <p className="intro">{t('SalzburgTreffpunktBewegung.intro')}</p>
      <p>{t('SalzburgTreffpunktBewegung.p1')}</p>
      <p>{t('SalzburgTreffpunktBewegung.p2')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.all')}, {t('desc.targetAudience.limitedNoGermanSkills')}, {t('desc.targetAudience.seniors')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}, {t('desc.difficultyLevel.moderate')}, {t('desc.difficultyLevel.difficult')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.yes')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.alone')}, {t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}>
          <li>Südtirolerplatz</li>
          <li>Bachstraße</li>
          <li>Josef-Kaut-Straße</li>
          <li>Hans-Webersdorfer-Straße</li>
          <li>Forellenwegsiedlung</li>
          <li>Auer-von-Welsbach-Straße</li>
        </ol>
          <span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img1}></img>
        <img src={img2}></img>
        <img src={img5}></img>
        <img src={img8}></img>
      </div>
      <div className='videoWrapper'>
        <video id="video" controls>
          <source src="./videos/WGEW.mp4" type="video/mp4" />
          Sorry, your browser doesn't support videos.
        </video>
      </div>
    </div>
  );
};

export default SalzburgTreffpunktBewegung;

