import React from 'react';
import { useTranslation } from 'react-i18next';
import Navigation from './Navigation';
import HeaderGraphic from './HeaderGraphic';

function Home() {
    const { t } = useTranslation();

    return (<>
        <HeaderGraphic />
        <div className='wrapper'>
            <h2>{t('home.h1')}</h2>
            <h3>{t('home.h2')}</h3>
            <p>{t('home.p1')}</p>
            <p>{t('home.p2')}</p>
        </div>
        <Navigation></Navigation>
        <div className='wrapper'>
            <h2>{t('activities.whatWasDone')}</h2>
            <ul>
                <li className='activities'><span className='activitiesHeader'>{t('activities.activity1.name')}</span>
                    <span>{t('activities.activity1.date')}</span>
                    <span>{t('activities.activity1.location')}</span>
                    <span>{t('activities.activity1.info')}</span>
                    <span>{t('activities.activity1.details')}</span>
                </li>
                <li className='activities'><span className='activitiesHeader'>{t('activities.activity2.name')}</span>
                    <span>{t('activities.activity2.date')}</span>
                    <span>{t('activities.activity2.location')}</span>
                    <span>{t('activities.activity2.info')}</span>
                    <span>{t('activities.activity2.details')}</span>
                </li>
                <li className='activities'><span className='activitiesHeader'>{t('activities.activity3.name')}</span>
                    <span>{t('activities.activity3.date')}</span>
                    <span>{t('activities.activity3.location')}</span>
                    <span>{t('activities.activity3.info')}</span>
                    <span>{t('activities.activity3.details')}</span>
                </li>
                <li className='activities'><span className='activitiesHeader'>{t('activities.activity4.name')}</span>
                    <span>{t('activities.activity4.date')}</span>
                    <span>{t('activities.activity4.location')}</span>
                    <span>{t('activities.activity4.info')}</span>
                    <span>{t('activities.activity4.details')}</span>
                </li>
            </ul>
        </div>
    </>
    );
};

export default Home;

