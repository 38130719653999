import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee9.png';
import img1 from '../images/renderings/9/09_GemeinsamSchaukeln_weiss.png';
import img3 from '../images/renderings/9/09_GemeinsamSchaukeln-CLOSEUP.png';
import img4 from '../images/renderings/9/09_GemeinsamSchaukeln-CLOSEUP02_weiss.png';
import img6 from '../images/renderings/9/09_Schaukeln.png';


function GemeinsamSchaukeln() {
  const { t } = useTranslation();

  return (
    <div className='wrapper'>
      <h1>{t('GemeinsamSchaukeln.title')}</h1>
      <LikeButton projectRef={"/GemeinsamSchaukeln"} />
      <p className="intro">{t('GemeinsamSchaukeln.intro')}</p>
      <p>{t('GemeinsamSchaukeln.p1')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.peopleWithDisabilities')}, {t('desc.targetAudience.adultsWithChildren')}, {t('desc.targetAudience.all')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.no')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}>
          <li>Lehener Park</li>
          <li>Stölzlpark</li>
          <li>Spielepark Süd</li>
          <li>Spielplatz Slavi-Soucek-Straße</li>
          <li>Hans-Donnerberg-Park</li>
          <li>Spielplatz Rauchenbichl</li>
          <li>Spielplatz Taxham</li>
        </ol>
          <span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img1}></img>
        <img src={img3}></img>
        <img src={img4}></img>
        <img src={img6}></img>
      </div>
    </div>
  );
};

export default GemeinsamSchaukeln;

