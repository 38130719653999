import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t } = useTranslation();

    return (
        <footer>
            <Link className="simple-link" to="/Imprint">{t('imprint.h1')}</Link>
            <p>{t('imprint.h2')}</p>
        </footer>
    );
};

export default Footer;
