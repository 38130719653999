import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee10.png';
import img1 from '../images/renderings/10/10_Tanz_Mit01.png';
import img2 from '../images/renderings/10/10_Tanz_Mit02.png';
import img4 from '../images/renderings/10/10_TanzMit-CLOSEUP02_weiss.png';
import img5 from '../images/renderings/10/10_TanzMit.png';
import img7 from '../images/renderings/10/10_TanzMit02_weiss_2.png';


function TanzMit() {
  const { t } = useTranslation();

  return (
    <div className='wrapper'>
      <h1>{t('TanzMit.title')}</h1>
      <LikeButton projectRef={"/TanzMit"} />
      <p className="intro">{t('TanzMit.intro')}</p>
      <p>{t('TanzMit.p1')}</p>
      <p>{t('TanzMit.p2')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.all')}, {t('desc.targetAudience.adultsWithChildren')}, {t('desc.targetAudience.seniors')}, {t('desc.targetAudience.peopleWithDisabilities')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}, {t('desc.difficultyLevel.moderate')}, {t('desc.difficultyLevel.difficult')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.yes')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}>
          <li>Europark</li>
          <li>Volksgarten</li>
          <li>Stadtwerk Lehen</li>
          <li>Vinzenz-Pallotti-Platz</li>
          <li>Voglspielplatz Lerchenstraße</li>
          <li>Preuschenpark</li>
          <li>Goethesiedlung</li>
        </ol>
          <span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img1}></img>
        <img src={img2}></img>
        <img src={img4}></img>
        <img src={img5}></img>
        <img src={img7}></img>
      </div>
    </div>
  );
};

export default TanzMit;