import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';
import { useTranslation } from 'react-i18next';

const Navigation = ({closeOverlay}) => {

  const { t } = useTranslation();

  return (
    <>
      <div className='wrapper'>
        <h2>{t('projects.h')}</h2>
        <p style={{ direction: 'ltr' }}>{t('projects.p')}</p>
      </div>
      <nav className='nav'>
        <ul>
          <li>
            <Link onClick={closeOverlay} to="/PopUpPool">{t('PopUpPool.title')}</Link>
          </li>
          <li>
            <Link onClick={closeOverlay} to="/BEwegen">{t('BEwegen.title')}</Link>
          </li>
          <li>
            <Link onClick={closeOverlay} to="/MoveAndSearch">{t('MoveAndSearch.title')}</Link>
          </li>
          <li>
            <Link onClick={closeOverlay} to="/WasserSpieleLehen">{t('WasserSpieleLehen.title')}</Link>

          </li>
          <li>
            <Link onClick={closeOverlay} to="/EnergieSpendeWald">{t('EnergieSpendeWald.title')}</Link>
          </li>
          <li>
            <Link onClick={closeOverlay} to="/TreppenWunder">{t('TreppenWunder.title')}</Link>
          </li>
          <li>
            <Link onClick={closeOverlay} to="/WillstDuMitMirGehen">{t('WillstDuMitMirGehen.title')}</Link>

          </li>
          <li>
            <Link onClick={closeOverlay} to="/SalzburgTreffpunktBewegung">{t('SalzburgTreffpunktBewegung.title')}</Link>
          </li>
          <li>
            <Link onClick={closeOverlay} to="/GemeinsamSchaukeln">{t('GemeinsamSchaukeln.title')}</Link>
          </li>
          <li>
            <Link onClick={closeOverlay} to="/TanzMit">{t('TanzMit.title')}</Link>

          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
