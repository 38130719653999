import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee1.png';

import img1 from '../images/renderings/1/01_Pool_weiss.png';
import img3 from '../images/renderings/1/01_Pool-CLOSEUP.png';
import img5 from '../images/renderings/1/01_Pool-CLOSEUP02.png';
import img7 from '../images/renderings/1/01_PopupPool_Hintergrund.png';

function PopUpPool() {
  const { t } = useTranslation();

  return (
    <div className='wrapper'>
      <h1>{t('PopUpPool.title')}</h1>
      <LikeButton projectRef={"/PopUpPool"} />
      <p className="intro">{t('PopUpPool.intro')}</p>
      <p>{t('PopUpPool.p1')}</p>
      <p>{t('PopUpPool.p2')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.all')}, {t('desc.targetAudience.adultsWithChildren')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}, {t('desc.difficultyLevel.moderate')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.no')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.alone')}, {t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}><li>Stadtwerk Lehen</li><li>Vierthalerstraße</li><li>Pillweinstraße</li></ol><span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img1}></img>
        <img src={img3}></img>
        <img src={img5}></img>
        <img src={img7}></img>
      </div>
    </div>
  );
};

export default PopUpPool;

