import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <ul className='language-switcher' style={{ direction: 'ltr' }}>
      <li>
        <button onClick={() => changeLanguage('de')}>Deutsch</button>
      </li>
      <li>
        <button onClick={() => changeLanguage('en')}>English</button>
      </li>
      <li>
        <button onClick={() => changeLanguage('bks')}>BiH/HR/S</button>
      </li>
      <li>
        <button onClick={() => changeLanguage('arab')}>Arabisch</button>
      </li>
      <li>
        <button onClick={() => changeLanguage('trk')}>Türkçe</button>
      </li>
      <li>
        <button onClick={() => changeLanguage('ukr')}>українська</button>
      </li>
      <li>
        <button onClick={() => changeLanguage('dfp')}>Dari/Farsi/Pashtu</button>
      </li>
    </ul>
  );
};

export default LanguageSwitcher;

