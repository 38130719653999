import React from 'react';
import { useTranslation } from 'react-i18next';
import { Random } from 'react-animated-text';

const HeaderGraphic = () => {
  const { t } = useTranslation();

  return (
    <div className="headerGraphic">
      <p>→ {t('home.headerLehen')}</p>
      <h1 style={{ direction: 'ltr' }}>{t('home.header10Ideas')}</h1>
      <h2 style={{ direction: 'ltr' }}>
        <Random
          text={t('home.headerMove')}
          effect="jump"
          effectChange={0.5}
          effectDuration={2}
        />
      </h2>
    </div>
  );
};

export default HeaderGraphic;
