// LikeButton.js
import React, { useEffect, useState } from 'react';
import { onValue, ref, runTransaction } from 'firebase/database';
import { db } from './Firebase';
import { ReactComponent as HeartFilled } from './images/heart-filled.svg';
import { ReactComponent as HeartEmpty } from './images/heart-empty.svg';
import { useTranslation } from 'react-i18next';

const LikeButton = ({ projectRef }) => {
  const dataRef = ref(db, projectRef);
  const [likes, setLikes] = useState(0);
  const [hasLiked, setHasLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const storageKey = `projectLikeStatus_${projectRef}`;
  const { t } = useTranslation();


  useEffect(() => {
    const fetchData = () => {
      onValue(dataRef, (snapshot) => {
        const fetchedData = snapshot.val();
        setLikes(fetchedData || 0);
        setLoading(false); // Set loading to false when data is retrieved
      });
    };

    fetchData();

    const likedStatus = sessionStorage.getItem(storageKey);
    if (likedStatus === 'liked') {
      setHasLiked(true);
    }

    return () => {
      // Cleanup function
    };
  }, [dataRef, storageKey]);

  const handleLikeClick = () => {
    runTransaction(dataRef, (currentData) => {
      if (!currentData) {
        currentData = 0; // Initialize likes to 1 if it doesn't exist
      }

      if (hasLiked && currentData != 0) {
        // User has already liked, decrement likes
        sessionStorage.removeItem(storageKey);
        setHasLiked(false);
        return currentData - 1;
      } else {
        // User hasn't liked, increment likes
        sessionStorage.setItem(storageKey, 'liked');
        setHasLiked(true);
        return currentData + 1;
      }
    }).then(() => {
      // No need to do anything here, likes are updated in the transaction callback
    });
  };

  return (
    <div className="likeButtonWrapper">
      <p><span className='likes'>{likes}{" "}</span><br></br>{t('like.like')}</p>
      <p>{t('like.close')}</p>
      {/* <button
        onClick={handleLikeClick}
        className="likeButton"
        disabled={loading}
      >
        <div className='heart'>{hasLiked ? <HeartFilled /> : <HeartEmpty />}</div>
        <span>{t('like.expl')}</span>
        <span style={{ direction: 'ltr' }}>{t('like.desc')}</span>
      </button> */}
    </div>
  );
};

export default LikeButton;
