import React from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from '../LikeButton';
import map from '../images/map/Karte_Idee7.png';

import img1 from '../images/renderings/7/07_WillsDuMitMirgehen.png';
import img2 from '../images/renderings/7/07_WillsDuMitMirgehen02.png';
import img5 from '../images/renderings/7/07_WillstDuMitMirGehen-CLOSEUP.png';
import img6 from '../images/renderings/7/07_WillstDuMitMirGehen.png';


function WillstDuMitMirGehen() {
  const { t } = useTranslation();

  return (
    <div className='wrapper'>
      <h1>{t('WillstDuMitMirGehen.title')}</h1>
      <LikeButton projectRef={"/WillstDuMitMirGehen"} />
      <p className="intro">{t('WillstDuMitMirGehen.intro')}</p>
      <p>{t('WillstDuMitMirGehen.p1')}</p>
      <p>{t('WillstDuMitMirGehen.p2')}</p>
      <p>{t('WillstDuMitMirGehen.p3')}</p>
      <ul>
        <li className='desc'><span className='descHeader'>{t('desc.targetAudience.targetAudience')}:</span><span>{t('desc.targetAudience.all')}, {t('desc.targetAudience.peopleWithDisabilities')}, {t('desc.targetAudience.limitedNoGermanSkills')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.difficultyLevel.difficultyLevel')}:</span><span>{t('desc.difficultyLevel.easy')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.digitalSupport.digitalSupport')}:</span><span>{t('desc.digitalSupport.yes')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.context.context')}:</span><span>{t('desc.context.togetherWithOthers')}</span></li>
        <li className='desc'><span className='descHeader'>{t('desc.locations')}:</span><ol style={{ direction: 'ltr' }}>
          <li>Josef-Mayburger-Kai</li>
          <li>Glanspitzpark</li>
          <li>Lehener Park</li>
          <li>Elisabethkai</li>
          <li>Cafe am Kai</li>
          <li>Fisch Krieg</li>
          <li>Christuskirche</li>
          <li>Mozartsteg</li>
          <li>Volksgarten</li>
          <li>Überfuhrsteg</li>
          <li>Bienenhof</li>
        </ol>
          <span><img className="location" src={map}></img></span></li>
      </ul>
      <h2>Renderings</h2>
      <div className="Renderings">
        <img src={img1}></img>
        <img src={img2}></img>
        <img src={img5}></img>
        <img src={img6}></img>
      </div>
      <div className='videoWrapper'>
        <video id="video" controls>
          <source src="./videos/WDMMG.mp4" type="video/mp4" />
          Sorry, your browser doesn't support videos.
        </video>
      </div>
    </div>
  );
};

export default WillstDuMitMirGehen;

