import React from 'react';
import Navigation from "./Navigation";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OverlayMenu = ({ closeOverlay }) => {

    const { t } = useTranslation();

    return (
        <div className="overlay-menu">
            <div className="overlay-content">
                <Navigation closeOverlay={closeOverlay}></Navigation>

                <div className='fixedButtonContainer'>
                    <Link className="fixedButton" onClick={closeOverlay}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="close-icon"
                            onClick={closeOverlay}
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path stroke="#ed7650" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        {t('close')}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default OverlayMenu;