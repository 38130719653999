import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const headerElement = document.getElementById('headerID'); // Replace 'yourHeaderId' with the actual ID of your header
    if (headerElement) {
      headerElement.scrollIntoView();
    } else {
      window.scrollTo(0, 0); // Fallback: scroll to the top if the header element is not found
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
